<template>
  <b-container fluid>
    <b-modal
      ref="modal-update"
      hide-footer
      :title="dataItem.company"
    >
      <update-form
        :data="dataItem"
        @handle-update="handleUpdate"
      />
    </b-modal>

    <b-modal
      ref="modal-create"
      hide-footer
    >
      <validation-observer #default="{ invalid }">
        <b-form>

          <b-form-group
            label="Empresa"
            label-for="company"
          >
            <validation-provider
              #default="{ errors }"
              name="Empresa"
              rules="required"
            >
              <b-form-input
                id="company"
                v-model="newItem.company"
                :state="errors.length > 0 ? false : null"
                placeholder="Empresa"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

          </b-form-group>

          <b-form-group
            label="CNPJ"
            label-for="tax_id"
          >
            <validation-provider
              #default="{ errors }"
              name="Documento"
              rules="required|tax_id"
            >
              <b-form-input
                id="company"
                v-model="newItem.document"
                v-mask="['###.###.###-##', '##.###.###/####-##']"
                placeholder="Documento"
                :state="errors.length > 0 ? false : null"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label="Saldo"
            label-for="balance"
          >
            <b-form-input
              id="balance"
              v-model="newItem.balance"
              v-money="money"
            />
          </b-form-group>

          <b-form-group
            label="Banco"
            label-for="bank"
          >
            <b-form-input
              id="bank"
              v-model="newItem.bank"
              readonly
            />
          </b-form-group>

          <b-form-group
            label="Nome do Banco"
            label-for="bankName"
          >
            <b-form-input
              id="bankName"
              v-model="newItem.bankName"
              readonly
            />
          </b-form-group>

          <b-form-group
            label="Agência"
            label-for="agency"
          >
            <b-form-input
              id="agency"
              v-model="newItem.agency"
              readonly
            />
          </b-form-group>

          <b-form-group
            label="Número da Conta"
            label-for="account"
          >
            <validation-provider
              #default="{ errors }"
              name="Conta"
              rules="required"
            >
              <b-form-input
                id="company"
                v-model="newItem.account"
                placeholder="Conta"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-row>
            <b-col md="12">
              <b-button
                variant="primary"
                style="width: 100%"
                :disabled="invalid"
                @click="createItem"
              >
                Criar
              </b-button>
            </b-col>

          </b-row>
        </b-form>

      </validation-observer>
    </b-modal>

    <b-modal
      ref="modal-guarantee"
      hide-footer
    >
      <validation-observer #default="{ invalid }">
        <b-form>
          <b-form-group
            label="Data de Início da Carteita Vencida"
            label-for="company"
          >
            <validation-provider
              #default="{ errors }"
              name="Data de Início da Carteita Vencida"
              rules="required"
            >
              <b-form-input
                id="company"
                v-model="guarantee.dueDateBeginExpired"
                type="date"
                :state="errors.length > 0 ? false : null"
                placeholder="Data de Início da Carteita Vencida"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

          </b-form-group>

          <b-form-group
            label="Data Final da Carteita Vencida"
            label-for="company"
          >
            <validation-provider
              #default="{ errors }"
              name="Data Final da Carteita Vencida"
              :rules="`required|begin-after-date:${guarantee.dueDateBeginExpired}`"
            >
              <b-form-input
                id="company"
                v-model="guarantee.dueDateEndExpired"
                type="date"
                :state="errors.length > 0 ? false : null"
                placeholder="Data Final da Carteita Vencida"
              />
              <small class="text-danger">{{ errors[0] }}</small>

            </validation-provider>

          </b-form-group>

          <b-form-group
            label="Data de Início da Carteita a Vencer"
            label-for="company"
          >
            <validation-provider
              #default="{ errors }"
              name="Data de Início da Carteita a Vencer"
              rules="required"
            >
              <b-form-input
                v-model="guarantee.dueDateBeginToBeExpired"
                type="date"
                :state="errors.length > 0 ? false : null"
                placeholder="Data de Início da Carteita a Vencer"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

          </b-form-group>

          <b-form-group
            label="Data Final da Carteira a Vencer"
            label-for="company"
          >
            <validation-provider
              #default="{ errors }"
              name="Data Final da Carteira a Vencer"
              :rules="`required|begin-after-date:${guarantee.dueDateBeginToBeExpired}`"
            >
              <b-form-input
                id="company"
                v-model="guarantee.dueDateEndToBeExpired"
                type="date"
                :state="errors.length > 0 ? false : null"
                placeholder="Data Final da Carteira a Vencer"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

          </b-form-group>

          <b-row>
            <b-col md="12">
              <b-button
                variant="primary"
                style="width: 100%"
                :disabled="invalid"
                @click="updateGuarantee"
              >
                Atualizar
              </b-button>
            </b-col>

          </b-row>
        </b-form>

      </validation-observer>
    </b-modal>

    <b-modal
      ref="modal-guarantee-tax"
      hide-footer
    >
      <validation-observer #default="{ invalid }">
        <b-form>
          <b-form-group
            label="Número do Bôrdero"
            label-for="company"
          >
            <validation-provider
              #default="{ errors }"
              name="Número do Bôrdero"
              rules="required|integer|positive"
            >
              <b-form-input
                id="company"
                v-model="guaranteeTax.bordero"
                :state="errors.length > 0 ? false : null"
                placeholder="Número do Bôrdero"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

          </b-form-group>

          <b-form-group
            label="Porcentagem"
            label-for="company"
          >
            <validation-provider
              #default="{ errors }"
              name="Porcentagem"
              rules="percent-bordero"
            >
              <b-input-group append="%">
                <b-form-input
                  id="company"
                  v-model="guaranteeTax.percent"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Porcentagem"
                />
              </b-input-group>

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

          </b-form-group>

          <b-form-group
            label="Taxa de Operação"
            label-for="tax"
          >
            <validation-provider
              #default="{ errors }"
              name="Taxa de Operação"
              rules="percent-bordero"
            >
              <b-input-group append="%">
                <b-form-input
                  id="tax"
                  v-model="guaranteeTax.tax"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Taxa de Operação"
                />
              </b-input-group>

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

          </b-form-group>

          <b-row>
            <b-col md="12">
              <b-button
                variant="primary"
                style="width: 100%"
                :disabled="invalid"
                @click="updateGuaranteeTax"
              >
                Atualizar
              </b-button>
            </b-col>

          </b-row>
        </b-form>

      </validation-observer>
    </b-modal>

    <b-card>
      <b-row class="px-1 justify-content-between">
        <b-button
          class="d-block"
          variant="primary"
          @click="create"
        >
          Criar Nova Conta
        </b-button>

        <b-row
          class="px-1"
          style="gap: 12px"
        >
          <b-button
            class="d-block"
            variant="primary"
            @click="showGuaranteeTax"
          >
            Atualizar Informações Borderô
          </b-button>

          <b-button
            class="d-block"
            variant="primary"
            @click="showGuarantee"
          >
            Atualizar Carteira a Vencer
          </b-button>
        </b-row>
      </b-row>

      <b-table
        class="text-center mt-1"
        :per-page="12"
        hover
        :current-page="page"
        :items="items"
        :fields="[
          { key: 'company', label: 'Razão Social'},
          { key: 'balance', label: 'Saldo'},
          { key: 'balanceAffiliationViainvest', label: 'Saldo Filial 102'},
          { key: 'balanceAffiliationViafomento', label: 'Saldo Filial 02'},
          { key: 'dailyMailings', label: 'Envios do Dia'},
          { key: 'ted', label: 'TED'},
        ]"
        @row-clicked="update"
      >
        <template #cell(balanceAffiliationViainvest)="data">
          {{ data.item.balanceAffiliationViainvest | value }}
        </template>

        <template #cell(balance)="data">
          {{ data.item.balance | value }}
        </template>

        <template #cell(balanceAffiliationViafomento)="data">
          {{ data.item.balanceAffiliationViafomento | value }}
        </template>

        <template #cell(dailyMailings)="data">
          {{ data.item.dailyMailings | value }}
        </template>

        <template #cell(ted)="data">
          {{ data.item.ted | value }}
        </template>
      </b-table>

      <b-pagination
        v-model="page"
        :total-rows="items.length"
        :per-page="12"
        align="center"
        size="sm"
        class="my-2"
      />
    </b-card>
  </b-container>
</template>

<script>
import {
    BInputGroup, BRow, BTable, BContainer, BCard, BPagination, BModal, BForm, BFormGroup, BFormInput, BButton, BCol,
} from 'bootstrap-vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import AccountScrowService from '@/service/account-scrow';

import UpdateForm from './Components/Forms/Update.vue';

import '@validations';

const removeSymbolsMoney = value => (typeof value === 'string'
    ? +(value.replace(/[R$ .]/g, '').replace(',', '.'))
    : value);

export default {
    components: {
        UpdateForm, BInputGroup, BRow, BTable, BContainer, BCard, BPagination, BModal, BForm, BFormGroup, BFormInput, BButton, BCol, ValidationObserver, ValidationProvider,
    },
    data() {
        return {
            page: 1,
            items: [],
            dataItem: {},
            newItem: {
                bank: '274',
                agency: '0001',
                bankName: 'MONEY PLUS',
                accountType: 'MONEY PLUS',
                ted: 0,
                depositDayPlusOne: 0,
                balanceAffiliationViainvest: 0,
                balanceAffiliationViafomento: 0,
            },
            guarantee: {},
            guaranteeTax: {},
            money: {
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                precision: 2,
                masked: false,
            },
        };
    },
    async mounted() {
        await this.getItems();
        await this.getGuarantee();
    },
    methods: {
        async getItems() {
            this.callLoading(true);
            const { data } = await AccountScrowService.show();

            this.items = data;
            this.callLoading(false);
        },
        update(item) {
            this.dataItem = JSON.parse(JSON.stringify(item));
            this.$refs['modal-update'].show();
        },

        async handleUpdate() {
            const isConfirmed = await this.confirmAnAction('Deseja realmente atualizar essa conta?');
            if (!isConfirmed) return;
            this.callLoading(true);

            const { status } = await AccountScrowService.update(this.dataItem);

            if (status === 202) {
                this.modalSuccess('Conta atualizada com sucesso');
                await this.getItems();
            } else {
                this.modalError('Não foi possível atualizar a conta');
            }

            this.callLoading(false);

            this.$refs['modal-update'].hide();
        },

        async revoke() {
            const isConfirmed = await this.confirmAnAction('Deseja realmente deletar essa conta?');
            if (!isConfirmed) return;
            this.callLoading(true);
            const { status } = await AccountScrowService.delete({
                id: this.dataItem.id,
            });

            if (status === 200) {
                this.modalSuccess('Conta revogada com sucesso');
                await this.getItems();
            } else {
                this.modalError('Não foi possível revogar a conta');
            }

            this.callLoading(false);
            this.$refs['modal-update'].hide();
        },

        create() {
            this.$refs['modal-create'].show();
        },

        async getGuarantee() {
            const { data } = await AccountScrowService.getGuarantee();
            this.guarantee = data;
        },

        async updateGuarantee() {
            const isConfirmed = await this.confirmAnAction('Deseja realmente atualizar carteira a vencer?');
            if (!isConfirmed) return;

            this.callLoading(true);
            const { status } = await AccountScrowService.updateGuarantee(this.guarantee);
            if (status === 200) {
                this.modalSuccess('Carteira atualizada com sucesso');
            } else {
                this.modalError('Não foi possível atualizar a carteira');
            }
            this.callLoading(false);
            this.$refs['modal-guarantee'].hide();
        },

        async createItem() {
            const isConfirmed = await this.confirmAnAction('Deseja realmente atualizar essa conta?');
            if (!isConfirmed) return;
            this.callLoading(true);
            const removeSymbolsTaxId = document => (document ? document.replace(/[^a-zA-Z0-9]/g, '') : undefined);

            const { status } = await AccountScrowService.create({
                ...this.newItem,
                document: removeSymbolsTaxId(this.newItem.document),
                balance: removeSymbolsMoney(this.newItem.balance),
            });

            if (status === 200) {
                this.modalSuccess('Conta criada com sucesso');
            } else if (status === 409) {
                this.modalError('Conta já existe');
            } else {
                this.modalError('Erro ao criar conta');
            }
            this.callLoading(false);
        },

        showGuarantee() {
            this.$refs['modal-guarantee'].show();
        },

        showGuaranteeTax() {
            this.$refs['modal-guarantee-tax'].show();
        },
        async updateGuaranteeTax() {
            const isConfirmed = await this.confirmAnAction('Deseja realmente atualizar a taxa de garantia?');
            if (!isConfirmed) return;

            this.callLoading(true);

            const { status } = await AccountScrowService.updateGuaranteeTax({
                bordero: this.guaranteeTax.bordero,
                percent: this.guaranteeTax.percent.replace(',', '.'),
                tax: this.guaranteeTax.tax.replace(',', '.'),
            });

            if (status === 200) {
                this.modalSuccess('Taxa de garantia atualizada');
            } else {
                this.modalError('Não foi possível atualizar taxa de garantia');
            }

            this.callLoading(false);
            this.$refs['modal-guarantee-tax'].hide();
        },
    },
};
</script>

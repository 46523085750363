<template>
  <b-input-group>
    <BInputGroupPrepend is-text>
      R$
    </BInputGroupPrepend>
    <BInputGroupPrepend>

      <b-form-select
        v-model="selectedOperation"
        style="border-radius: 0 !important"
        :options="['-', '+']"
      />
    </BInputGroupPrepend>
    <b-form-input
      :id="id"
      v-model="value"
      v-money="money"
    />
  </b-input-group>
</template>

<script>
import {
    BFormInput, BInputGroup, BInputGroupPrepend, BFormSelect,
} from 'bootstrap-vue';

export default {
    components: {
        BFormInput, BInputGroup, BInputGroupPrepend, BFormSelect,
    },

    props: {
        id: {
            type: String,
            required: true,
        },

        initValue: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {
            value: null,
            money: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                precision: 2,
                masked: false,
            },
            selectedOperation: '+',
        };
    },

    watch: {
        value(newValue) {
            const formatted = this.formatValue(newValue);
            this.$emit('change', +formatted);
        },

        selectedOperation() {
            if (this.value) {
                const formatted = this.formatValue(this.value);
                this.$emit('change', +formatted);
            }
        },
    },

    mounted() {
        this.value = this.initValue;

        if (this.value < 0) {
            this.selectedOperation = '-';
        }
    },

    methods: {
        formatValue(value) {
            return (+value.replace(/[R$ .]/g, '').replace(',', '.')) * (this.selectedOperation === '-' ? -1 : 1);
        },
    },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[_c('b-modal',{ref:"modal-update",attrs:{"hide-footer":"","title":_vm.dataItem.company}},[_c('update-form',{attrs:{"data":_vm.dataItem},on:{"handle-update":_vm.handleUpdate}})],1),_c('b-modal',{ref:"modal-create",attrs:{"hide-footer":""}},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',[_c('b-form-group',{attrs:{"label":"Empresa","label-for":"company"}},[_c('validation-provider',{attrs:{"name":"Empresa","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company","state":errors.length > 0 ? false : null,"placeholder":"Empresa"},model:{value:(_vm.newItem.company),callback:function ($$v) {_vm.$set(_vm.newItem, "company", $$v)},expression:"newItem.company"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"CNPJ","label-for":"tax_id"}},[_c('validation-provider',{attrs:{"name":"Documento","rules":"required|tax_id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(['###.###.###-##', '##.###.###/####-##']),expression:"['###.###.###-##', '##.###.###/####-##']"}],attrs:{"id":"company","placeholder":"Documento","state":errors.length > 0 ? false : null},model:{value:(_vm.newItem.document),callback:function ($$v) {_vm.$set(_vm.newItem, "document", $$v)},expression:"newItem.document"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Saldo","label-for":"balance"}},[_c('b-form-input',{directives:[{name:"money",rawName:"v-money",value:(_vm.money),expression:"money"}],attrs:{"id":"balance"},model:{value:(_vm.newItem.balance),callback:function ($$v) {_vm.$set(_vm.newItem, "balance", $$v)},expression:"newItem.balance"}})],1),_c('b-form-group',{attrs:{"label":"Banco","label-for":"bank"}},[_c('b-form-input',{attrs:{"id":"bank","readonly":""},model:{value:(_vm.newItem.bank),callback:function ($$v) {_vm.$set(_vm.newItem, "bank", $$v)},expression:"newItem.bank"}})],1),_c('b-form-group',{attrs:{"label":"Nome do Banco","label-for":"bankName"}},[_c('b-form-input',{attrs:{"id":"bankName","readonly":""},model:{value:(_vm.newItem.bankName),callback:function ($$v) {_vm.$set(_vm.newItem, "bankName", $$v)},expression:"newItem.bankName"}})],1),_c('b-form-group',{attrs:{"label":"Agência","label-for":"agency"}},[_c('b-form-input',{attrs:{"id":"agency","readonly":""},model:{value:(_vm.newItem.agency),callback:function ($$v) {_vm.$set(_vm.newItem, "agency", $$v)},expression:"newItem.agency"}})],1),_c('b-form-group',{attrs:{"label":"Número da Conta","label-for":"account"}},[_c('validation-provider',{attrs:{"name":"Conta","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company","placeholder":"Conta","state":errors.length > 0 ? false : null},model:{value:(_vm.newItem.account),callback:function ($$v) {_vm.$set(_vm.newItem, "account", $$v)},expression:"newItem.account"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-button',{staticStyle:{"width":"100%"},attrs:{"variant":"primary","disabled":invalid},on:{"click":_vm.createItem}},[_vm._v(" Criar ")])],1)],1)],1)]}}])})],1),_c('b-modal',{ref:"modal-guarantee",attrs:{"hide-footer":""}},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',[_c('b-form-group',{attrs:{"label":"Data de Início da Carteita Vencida","label-for":"company"}},[_c('validation-provider',{attrs:{"name":"Data de Início da Carteita Vencida","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company","type":"date","state":errors.length > 0 ? false : null,"placeholder":"Data de Início da Carteita Vencida"},model:{value:(_vm.guarantee.dueDateBeginExpired),callback:function ($$v) {_vm.$set(_vm.guarantee, "dueDateBeginExpired", $$v)},expression:"guarantee.dueDateBeginExpired"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Data Final da Carteita Vencida","label-for":"company"}},[_c('validation-provider',{attrs:{"name":"Data Final da Carteita Vencida","rules":("required|begin-after-date:" + (_vm.guarantee.dueDateBeginExpired))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company","type":"date","state":errors.length > 0 ? false : null,"placeholder":"Data Final da Carteita Vencida"},model:{value:(_vm.guarantee.dueDateEndExpired),callback:function ($$v) {_vm.$set(_vm.guarantee, "dueDateEndExpired", $$v)},expression:"guarantee.dueDateEndExpired"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Data de Início da Carteita a Vencer","label-for":"company"}},[_c('validation-provider',{attrs:{"name":"Data de Início da Carteita a Vencer","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"date","state":errors.length > 0 ? false : null,"placeholder":"Data de Início da Carteita a Vencer"},model:{value:(_vm.guarantee.dueDateBeginToBeExpired),callback:function ($$v) {_vm.$set(_vm.guarantee, "dueDateBeginToBeExpired", $$v)},expression:"guarantee.dueDateBeginToBeExpired"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Data Final da Carteira a Vencer","label-for":"company"}},[_c('validation-provider',{attrs:{"name":"Data Final da Carteira a Vencer","rules":("required|begin-after-date:" + (_vm.guarantee.dueDateBeginToBeExpired))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company","type":"date","state":errors.length > 0 ? false : null,"placeholder":"Data Final da Carteira a Vencer"},model:{value:(_vm.guarantee.dueDateEndToBeExpired),callback:function ($$v) {_vm.$set(_vm.guarantee, "dueDateEndToBeExpired", $$v)},expression:"guarantee.dueDateEndToBeExpired"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-button',{staticStyle:{"width":"100%"},attrs:{"variant":"primary","disabled":invalid},on:{"click":_vm.updateGuarantee}},[_vm._v(" Atualizar ")])],1)],1)],1)]}}])})],1),_c('b-modal',{ref:"modal-guarantee-tax",attrs:{"hide-footer":""}},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',[_c('b-form-group',{attrs:{"label":"Número do Bôrdero","label-for":"company"}},[_c('validation-provider',{attrs:{"name":"Número do Bôrdero","rules":"required|integer|positive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company","state":errors.length > 0 ? false : null,"placeholder":"Número do Bôrdero"},model:{value:(_vm.guaranteeTax.bordero),callback:function ($$v) {_vm.$set(_vm.guaranteeTax, "bordero", $$v)},expression:"guaranteeTax.bordero"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Porcentagem","label-for":"company"}},[_c('validation-provider',{attrs:{"name":"Porcentagem","rules":"percent-bordero"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"append":"%"}},[_c('b-form-input',{attrs:{"id":"company","state":errors.length > 0 ? false : null,"placeholder":"Porcentagem"},model:{value:(_vm.guaranteeTax.percent),callback:function ($$v) {_vm.$set(_vm.guaranteeTax, "percent", $$v)},expression:"guaranteeTax.percent"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Taxa de Operação","label-for":"tax"}},[_c('validation-provider',{attrs:{"name":"Taxa de Operação","rules":"percent-bordero"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"append":"%"}},[_c('b-form-input',{attrs:{"id":"tax","state":errors.length > 0 ? false : null,"placeholder":"Taxa de Operação"},model:{value:(_vm.guaranteeTax.tax),callback:function ($$v) {_vm.$set(_vm.guaranteeTax, "tax", $$v)},expression:"guaranteeTax.tax"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-button',{staticStyle:{"width":"100%"},attrs:{"variant":"primary","disabled":invalid},on:{"click":_vm.updateGuaranteeTax}},[_vm._v(" Atualizar ")])],1)],1)],1)]}}])})],1),_c('b-card',[_c('b-row',{staticClass:"px-1 justify-content-between"},[_c('b-button',{staticClass:"d-block",attrs:{"variant":"primary"},on:{"click":_vm.create}},[_vm._v(" Criar Nova Conta ")]),_c('b-row',{staticClass:"px-1",staticStyle:{"gap":"12px"}},[_c('b-button',{staticClass:"d-block",attrs:{"variant":"primary"},on:{"click":_vm.showGuaranteeTax}},[_vm._v(" Atualizar Informações Borderô ")]),_c('b-button',{staticClass:"d-block",attrs:{"variant":"primary"},on:{"click":_vm.showGuarantee}},[_vm._v(" Atualizar Carteira a Vencer ")])],1)],1),_c('b-table',{staticClass:"text-center mt-1",attrs:{"per-page":12,"hover":"","current-page":_vm.page,"items":_vm.items,"fields":[
        { key: 'company', label: 'Razão Social'},
        { key: 'balance', label: 'Saldo'},
        { key: 'balanceAffiliationViainvest', label: 'Saldo Filial 102'},
        { key: 'balanceAffiliationViafomento', label: 'Saldo Filial 02'},
        { key: 'dailyMailings', label: 'Envios do Dia'},
        { key: 'ted', label: 'TED'} ]},on:{"row-clicked":_vm.update},scopedSlots:_vm._u([{key:"cell(balanceAffiliationViainvest)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("value")(data.item.balanceAffiliationViainvest))+" ")]}},{key:"cell(balance)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("value")(data.item.balance))+" ")]}},{key:"cell(balanceAffiliationViafomento)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("value")(data.item.balanceAffiliationViafomento))+" ")]}},{key:"cell(dailyMailings)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("value")(data.item.dailyMailings))+" ")]}},{key:"cell(ted)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("value")(data.item.ted))+" ")]}}])}),_c('b-pagination',{staticClass:"my-2",attrs:{"total-rows":_vm.items.length,"per-page":12,"align":"center","size":"sm"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
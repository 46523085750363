<template>
  <b-form>

    <b-form-group
      label="Saldo Filial 102"
      label-for="filial-balance-102"
    >
      <money-input
        id="filial-balance-102"
        :init-value="data.balanceAffiliationViainvest"
        @change="value => updateState('balanceAffiliationViainvest', value)"
      />
    </b-form-group>

    <b-form-group
      label="Envios do Dia"
      label-for="daily_mailings"
    >
      <money-input
        id="daily_mailings"
        :init-value="data.dailyMailings"
        @change="value => updateState('dailyMailings', value)"
      />
    </b-form-group>

    <b-form-group
      label="TED"
      label-for="ted"
    >
      <money-input
        id="ted"
        :init-value="data.ted"
        @change="value => updateState('ted', value)"
      />
    </b-form-group>

    <b-form-group
      label="Saldo Filial 02"
      label-for="filial-balance-02"
    >
      <money-input
        id="filial-balance-02"
        :init-value="data.balanceAffiliationViafomento"
        @change="value => updateState('balanceAffiliationViafomento', value)"
      />
    </b-form-group>

    <b-row>
      <b-col md="6">
        <b-button
          variant="primary"
          style="width: 100%"
          @click="$emit('handle-update', data.id)"
        >
          Atualizar
        </b-button>
      </b-col>

      <b-col md="6">
        <b-button
          style="width: 100%"
          variant="danger"
          @click="$emit('handle-revoke', data.id)"
        >
          Deletar
        </b-button>
      </b-col>

    </b-row>
  </b-form>
</template>

<script>
import {
    BForm, BFormGroup, BRow, BCol, BButton,
} from 'bootstrap-vue';

import MoneyInput from '../MoneyInput.vue';

export default {
    components: {
        BForm, BFormGroup, MoneyInput, BRow, BCol, BButton,
    },

    props: {
        data: {
            type: Object,
            required: true,
        },
    },

    methods: {
        updateState(state, value) {
            this.data[state] = value;
        },
    },
};
</script>

import methods from '@/enums/methods';
import Thoth from '../base/thoth';

const BASE = '/accounts/escrow';

class AccountScrowService extends Thoth {
    show() {
        return this.fetch({
            url: `${BASE}/show`,
        });
    }

    create({
        company,
        document,
        agency,
        bank,
        account,
        bankName,
        accountType,
        balance,
    }) {
        return this.fetch({
            url: `${BASE}/create`,
            data: {
                company,
                document,
                agency,
                bank,
                account,
                bankName,
                accountType,
                balance,
            },
            method: methods.POST,
        });
    }

    update(data) {
        return this.fetch({
            url: `${BASE}/update`,
            params: { id: data.id },
            data: {
                ...data,
                id: undefined,
            },
            method: methods.PUT,
        });
    }

    delete({
        id,
    }) {
        return this.fetch({
            url: `${BASE}/delete`,
            params: { id },
            method: methods.DELETE,
        });
    }

    getGuarantee() {
        return this.fetch({
            url: 'show/guarantee',
        });
    }

    updateGuarantee(data) {
        return this.fetch({
            url: 'update/guarantee',
            method: methods.PUT,
            data,
        });
    }

    updateGuaranteeTax({
        percent, bordero, tax,
    }) {
        return this.fetch({
            url: 'update/percent/borderos',
            method: methods.PUT,
            params: {
                percent,
                bordero,
                tax,
            },
        });
    }
}

export default new AccountScrowService();
